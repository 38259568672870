var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Autocomplete, TextField, Tooltip } from '@mui/material';
// translation
import { useLocales } from '../../locales';
import { isObjectEmpty } from '../../utils';
export default function RHFAutocomplete(_a) {
    var name = _a.name, label = _a.label, helperText = _a.helperText, options = _a.options, _b = _a.soloSelected, soloSelected = _b === void 0 ? false : _b, _c = _a.required, required = _c === void 0 ? false : _c, other = __rest(_a, ["name", "label", "helperText", "options", "soloSelected", "required"]);
    var control = useFormContext().control;
    var translate = useLocales().translate;
    return (_jsx(Controller, { name: name, control: control, defaultValue: [], render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsx(Tooltip, __assign({ title: "".concat(translate(helperText)) || "".concat(translate(label)) }, { children: _jsx(Autocomplete, __assign({}, field, { onChange: function (_, data) {
                        var myArray = data;
                        if (soloSelected && myArray && (myArray === null || myArray === void 0 ? void 0 : myArray.length) > 0) {
                            // 
                            field.onChange([myArray === null || myArray === void 0 ? void 0 : myArray.pop()]);
                        }
                        else {
                            field.onChange(myArray);
                        }
                    }, options: options, value: isObjectEmpty(field.value) ? [] : field.value, filterSelectedOptions: true, renderInput: function (params) { return (_jsx(TextField, __assign({ label: "".concat(translate(label), " "), name: name, error: !!error, value: field.value, helperText: error && "".concat(translate(error === null || error === void 0 ? void 0 : error.message), " "), required: required }, params))); } }, other)) })));
        } }));
}
export function RHFAutocompleteOne(_a) {
    var name = _a.name, label = _a.label, helperText = _a.helperText, codeInOrderOfName = _a.codeInOrderOfName, options = _a.options, other = __rest(_a, ["name", "label", "helperText", "codeInOrderOfName", "options"]);
    var control = useFormContext().control;
    var _b = useState(''), textFieldValue = _b[0], setTextFieldValue = _b[1];
    var translate = useLocales().translate;
    return (_jsx(Controller, { name: name, control: control, defaultValue: [], render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsx(Tooltip, __assign({ title: "".concat(translate(helperText)) || "".concat(translate(label)) }, { children: _jsx(Autocomplete, __assign({}, field, { onChange: function (_, data) {
                        field.onChange(data === null || data === void 0 ? void 0 : data.id);
                    }, options: textFieldValue !== ''
                        ? options.filter(function (value) {
                            var _a, _b, _c, _d;
                            return codeInOrderOfName
                                ? (_a = "".concat(translate(value === null || value === void 0 ? void 0 : value.code))) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes((_b = "".concat(translate(textFieldValue))) === null || _b === void 0 ? void 0 : _b.toLowerCase())
                                : (_c = "".concat(translate(value === null || value === void 0 ? void 0 : value.name))) === null || _c === void 0 ? void 0 : _c.toLowerCase().includes((_d = "".concat(translate(textFieldValue))) === null || _d === void 0 ? void 0 : _d.toLowerCase());
                        })
                        : options, filterOptions: function (optionFiltered, state) { return optionFiltered; }, value: field.value, renderInput: function (params) { return (_jsx(TextField, __assign({ label: "".concat(translate(label)), name: name, error: !!error, value: textFieldValue, onChange: function (e) { return setTextFieldValue(e.target.value); }, helperText: error ? "".concat(translate(error === null || error === void 0 ? void 0 : error.message)) : "".concat(translate(helperText)) }, params))); } }, other)) })));
        } }));
}
